export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: "About",
    headline: "",
    description: "Inifinita, a visionary organization dedicated to promoting STEM education and computer literacy among young students, partners with the NASA Space Apps Challenge to shape a brighter future. We believe in the power of knowledge and innovation, and our mission is to empower the next generation of thinkers, creators, and problem solvers. The NASA Space Apps Challenge is an international event that unites talented minds to pioneer cutting-edge solutions for space exploration and global challenges. Participants harness NASA's extensive data sets and tools to address critical issues facing our world today. Together, Inifinita and the NASA Space Apps Challenge inspire and transform young minds, fostering a passion for science, technology, engineering, and mathematics. Join us in our journey to nurture innovation, ignite curiosity, and shape a better world.",
    buttonLabel: "Get Started",
    RegLink: "https://docs.google.com/forms/d/e/1FAIpQLSf2eWjqeOYi6tHNw70FSd_aKzCDkZ-QoJEuY1qVv1uBYWkijw/viewform?usp=sf_link",
    imgStart: true,
    img: require("../../images/cover-art.png"),
    alt: "car",
    dark: true,
    primary: true,
    darkText: false,
}